import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WCUImage from "../../../assets/whyChooseUs.png";

const WhyChooseUs = () => {
  const faqs = [
    {
      question: "Proven Expertise in Healthcare & Pharma Recruitment",
      answer:
        "Trusted by 100+ leading healthcare and pharmaceutical organizations, we specialize in sourcing top-tier talent with unmatched speed and reliability.",
    },
    {
      question: "40+ Healthcare & Pharma Talent Acquisition Managers",
      answer:
        "A dedicated team of specialists ensures precise hiring solutions tailored to the unique demands of the healthcare and pharmaceutical industries.",
    },
    {
      question: "Customized Solutions for Every Need",
      answer:
        "Our tailored approach ensures every candidate aligns with your technical requirements and organizational culture.",
    },
    {
      question: "Commitment to Quality and Speed",
      answer:
        "With a focus on precision and excellence, we deliver seamless hiring experiences that enable faster onboarding and scaling.",
    },
    {
      question: "Access to an Expansive Talent Pool",
      answer:
        "A vast database of 20 Lacs+ healthcare and pharma professionals ensures instant access to the right candidates for your organization.",
    },
  ];

  return (
    <Container
      sx={{
        padding: 4,
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        textAlign="center"
        marginBottom={4}
        fontWeight="bold"
        color="#603890"
      >
        Why Choose HealthPharma Talents?
      </Typography>
      <Grid container spacing={4} alignItems="center">
        {/* Left Section: Image */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "100%",
              backgroundImage: `url(${WCUImage})`, // Replace with your actual image URL
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 2,
              minHeight: "400px",
            }}
          />
        </Grid>

        {/* Right Section: Accordion */}
        <Grid item xs={12} md={6}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              defaultExpanded={index === 0} // This will make the first accordion open by default
              sx={{
                marginBottom: 2,
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                "&:before": {
                  display: "none", // Removes the default divider line
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#603890" }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  backgroundColor: "#f5f5f5",
                  "&:hover": {
                    backgroundColor: "#eaeaea",
                  },
                  borderBottom: "1px solid #ddd",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#603890" }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography variant="body1">{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

        </Grid>
      </Grid>
    </Container>
  );
};

export default WhyChooseUs;
