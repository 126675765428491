import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

const pages = ["Home", "About", "Offerings", "Contact"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [scrolling, setScrolling] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Track scrolling
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#22004C", //#8EEAEA
        boxShadow: 0,
        height: scrolling ? "80px" : "120px", // Change height based on scroll
        transition: "height 0.3s ease", // Smooth transition for height change
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            mt: scrolling ? 1 : 2,
            height: "100%",
            justifyContent: "space-around",
            transition: "margin-top 0.3s ease-in-out", // Added smooth transition for mt
          }}
        >
          {/* Logo Section */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                fontFamily: "Metropolis, sans-serif",
                fontWeight: 800,
                letterSpacing: ".1rem",
                color: "#fff",
                textDecoration: "none",
                ml: 1,
                fontSize: "3.5rem",
              }}
            >
              Health Pharma <span style={{ color: "#8EEAEA" }}>Talents</span>
            </Typography>
          </Box>

          {/* Mobile Menu Icon */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="open menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <NavLink
                    to={`/${page.toLowerCase().replace(/\s+/g, "")}`}
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "#603890" : "black",
                      fontWeight: isActive ? "bold" : "normal",
                      fontSize: "1.2rem",
                    })}
                  >
                    {page}
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Desktop Navigation Links */}
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            {pages.map((page) => (
              <NavLink
                key={page}
                to={`/${page.toLowerCase().replace(/\s+/g, "")}`}
                style={({ isActive }) => ({
                  textDecoration: 'none',
                  color: '#fff',
                                    borderBottom: isActive ? '3px solid #fff' : 'none',
                  margin: '0 15px',
                  paddingBottom: '5px',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                })}
              >
                {page}
              </NavLink>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
